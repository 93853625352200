import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Label from "../../Components/Label/Label";
import Layout from "../../Components/Layout/Layout";
import Navbar from "../../Components/Navbar/Navbar";
import Button from "../../Components/Button/Button";
import Icon from "../../Assets/Icon/Icon";
import Modal from "../../Components/Modal/Modal";
import { useFavorites } from "../../Context/FavoritesProvider";
import ImageSlider from "../../Components/ImageSlider/ImageSlider";
import Tooltip from "../../Components/Tooltip/Tooltip";
import Footer from "../../Components/Footer/Footer";
import { getErrorMessage } from "../../Utils/errorUtils";
import ErrorHandler from "../../Components/ErrorHandler/ErrorHandler";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import useGetCurrency from "../../Hooks/useGetCurrency";
import { ContentContext } from "../../Context/ContentProvider";

const DetailTemplate = () => {
  const slug = useParams();
  const [detailTemplate, setDetailTemplate] = useState({
    id: "",
    attributes: {
      website_name: "",
      website_description: "",
      website_start_price: "",
      website_count_sold: "",
      slug: "",
      template_website_category: { data: { attributes: { desc: "" } } },
      template_website_packets: { data: [] },
      website_image: {
        data: [{ id: "", attributes: { url: "", name: "" } }],
      },
      website_feature: {
        back_office: [],
        website: [],
      },
    },
  });
  const [showAccordion, setShowAccordion] = useState({
    freeTry: true,
    packet: [],
  });
  const { content } = useContext(ContentContext);

  const [loadingDetailTemplate, setLoadingDetailTemplate] = useState(false);
  const [showModal, setShowModal] = useState({
    download: false,
    share: { show: false, url: "" },
    video: { show: false, url: "" },
  });
  const { toggleFavorite, isFavorite } = useFavorites();
  const [relatedTemplate, setRelatedTemplate] = useState([]);
  const [copy, setCopy] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("Deskripsi");
  const navigate = useNavigate();
  const currency = useGetCurrency();
  const tabs = ["Deskripsi", "Fitur"];

  const handleDetailTemplate = useCallback(async () => {
    setLoadingDetailTemplate(true);
    setError(null);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/template-websites?filters[slug][$eq]=${slug.slug}&populate=*`
        )
        .then((val) => {
          setDetailTemplate(val.data.data[0]);
          const packetAccordion =
            val.data.data[0].attributes.template_website_packets.data.map(
              (el) => ({
                packetCode: el.attributes.packet_code,
                open: el.attributes.packet_additional_info.best_deal,
                isFree: el.attributes.packet_additional_info.isFree1Mnt,
              })
            );

          setShowAccordion((accordion) => ({
            ...accordion,
            packet: packetAccordion,
          }));
          return axios.get(
            `${process.env.REACT_APP_API_URL}/api/template-websites?sort[0]=website_count_sold:desc&filters[template_website_category][$eq]=${val.data.data[0].attributes.template_website_category.data.id}&filters[id][$ne]=${val.data.data[0].id}&pagination[pageSize]=4&populate=*`
          );
        })
        .then((response) => {
          setRelatedTemplate(response.data.data);
        });
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setLoadingDetailTemplate(false);
    }
  }, [slug]);

  useEffect(() => {
    handleDetailTemplate();
  }, [handleDetailTemplate]);

  const handleShareWebsite = (website_name, link) => {
    const message = `Hai, ini adalah Website ${website_name}, untuk detailnya kamu dapat lihat di link ini  ${link}.`;
    const whatsappUrl = `https://wa.me?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };

  const handleNeedHelp = () => {
    const message = `Halo, saya punya kebutuhan Website / Paket Berlangganan lain`;
    const whatsappUrl = `https://wa.me/${
      content.data.attributes.admin_phone_number
    }?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };

  return (
    <div className="bg-slate-50 dark:bg-gradient-to-b dark:from-slate-900 dark:to-slate-800">
      <Navbar />
      <Layout className={"mb-10"}>
        {error !== null ? (
          <ErrorHandler error={error} onRetry={handleDetailTemplate} /> //error handle
        ) : (
          <>
            {loadingDetailTemplate ? (
              <div className="flex flex-col gap-2">
                <div className="bg-slate-200 dark:bg-slate-600 rounded-lg xl:w-2/3 w-full h-6 animate-pulse" />
                <div className="bg-slate-200 dark:bg-slate-600 rounded-lg xl:w-2/3 w-full h-6 animate-pulse" />
                <div className="bg-slate-200 dark:bg-slate-600 rounded-lg xl:w-2/3 w-full h-6 animate-pulse" />
                <div className="flex xl:flex-row flex-col gap-2">
                  <div className="bg-slate-200 dark:bg-slate-600 rounded-lg xl:w-2/3 w-full h-[500px] animate-pulse" />
                  <div className="bg-slate-200 dark:bg-slate-600 rounded-lg xl:w-1/3 w-full h-[500px] animate-pulse" />
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="flex flex-row">
                  <Breadcrumb
                    categoryName={
                      detailTemplate.attributes.template_website_category.data
                        .attributes.desc
                    }
                    categoryPath={`?category=${detailTemplate.attributes.template_website_category.data.attributes.slug}`}
                  />
                </div>
                <Label
                  type={"section-title"}
                  className={"w-full text-black dark:text-white"}
                >
                  {detailTemplate.attributes.website_name}
                </Label>
                <div className="flex flex-row gap-4 h-full">
                  <div className="xl:basis-8/12 w-full flex flex-col gap-4 h-full">
                    <div className="flex items-center gap-2 w-full justify-between">
                      <p className="text-gray-500 font-custom dark:text-gray-200">
                        {detailTemplate.attributes.website_count_sold} 20 user
                        sudah berlangganan
                      </p>
                      <div className="flex items-center gap-2">
                        <Button
                          type={"icon"}
                          className="flex items-center justify-center !p-3 !rounded-full backdrop-blur-sm bg-gray-400/20 hover:bg-gray-700/20"
                          onClick={() =>
                            toggleFavorite(
                              detailTemplate.id,
                              detailTemplate.attributes.slug
                            )
                          }
                        >
                          {isFavorite(detailTemplate.id) ? (
                            <Icon name={"heartFill"} className={"h-5 w-5"} />
                          ) : (
                            <Icon name={"heart"} className={"h-5 w-5"} />
                          )}
                        </Button>
                        <Button
                          type={"icon"}
                          className="flex items-center justify-center !p-3 rounded-full backdrop-blur-sm bg-gray-400/20 hover:bg-gray-700/20"
                          onClick={() =>
                            setShowModal({
                              ...showModal,
                              share: {
                                show: !showModal.share.show,
                                url: window.location.href,
                              },
                            })
                          }
                        >
                          <Icon name={"share"} className={"h-5 w-5"} />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="xl:basis-4/12 xl:flex flex-col gap-2 hidden"></div>
                </div>
                <div className="flex xl:flex-row flex-col gap-4 h-full">
                  <div className="basis-8/12 flex flex-col gap-4 h-full">
                    <ImageSlider
                      images={detailTemplate.attributes.website_image.data}
                    />

                    <div className="flex space-x-2 border-b border-gray-200">
                      {tabs.map((tab, index) => (
                        <button
                          key={index}
                          onClick={() => setActiveTab(tab)}
                          className={`${
                            activeTab === tab
                              ? "border-blue-500 text-blue-500"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                          } whitespace-nowrap py-2 px-1 border-b-2 font-medium font-custom`}
                        >
                          {tab}
                        </button>
                      ))}
                    </div>
                    <div>
                      {activeTab === "Deskripsi" && (
                        <div className="flex flex-col rounded-xl bg-white dark:bg-white/10 px-4 py-2 gap-4">
                          <Label
                            type={"detail-desc"}
                            className={"text-black dark:text-white"}
                          >
                            {detailTemplate.attributes.website_description}
                          </Label>
                        </div>
                      )}
                      {activeTab === "Fitur" && (
                        <div className="flex flex-col gap-2 rounded-xl bg-white dark:bg-white/10 px-4 py-2 font-custom text-black dark:text-white">
                          <div>
                            <p className="text-lg font-semibold ">Website</p>
                            <ul>
                              {detailTemplate.attributes.website_feature.website.map(
                                (el, index) => (
                                  <li
                                    key={index}
                                    className="flex gap-1 items-center"
                                  >
                                    {" "}
                                    <Icon
                                      name={"check-cirle"}
                                      className={"h-4 w-4 text-green-500"}
                                    />
                                    {el}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                          <div>
                            <p className="text-lg font-semibold ">
                              Content Management
                            </p>
                            <ul>
                              {detailTemplate.attributes.website_feature.back_office.map(
                                (el, index) => (
                                  <li
                                    key={index}
                                    className="flex gap-1 items-center"
                                  >
                                    {" "}
                                    <Icon
                                      name={"check-cirle"}
                                      className={"h-4 w-4 text-green-500"}
                                    />
                                    {el}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="basis-4/12 flex flex-col gap-2 font-custom">
                    <ul className="flex flex-col gap-2">
                      {detailTemplate.attributes.template_website_packets.data.map(
                        (el, index) => (
                          <div key={index}>
                            <div className="flex flex-col px-4 py-2 bg-white dark:bg-white/10 rounded-lg">
                              <div
                                className="flex items-center justify-between"
                                onClick={() =>
                                  setShowAccordion((prev) => ({
                                    ...prev,
                                    packet: prev.packet.map((packet) =>
                                      packet.packetCode ===
                                      el.attributes.packet_code
                                        ? { ...packet, open: !packet.open }
                                        : packet
                                    ),
                                  }))
                                }
                              >
                                <div>
                                  {el.attributes.packet_additional_info
                                    .best_deal === true &&
                                    el.attributes.packet_additional_info
                                      .isFree1Mnt !== true && (
                                      <span className="flex items-center gap-1 text-xs bg-blue-100 text-blue-500 rounded-full px-2 py-0.5 justify-center w-fit">
                                        {" "}
                                        <Icon
                                          name={"award"}
                                          className={"h-3 w-3"}
                                        />
                                        Best Deal
                                      </span>
                                    )}
                                  <p className="font-semibold text-black dark:text-white">
                                    {el.attributes.packet_name}
                                  </p>

                                  {el.attributes.packet_additional_info
                                    .isFree1Mnt !== true && (
                                    <div className="flex items-end">
                                      <p className="text-black dark:text-white">
                                        {currency(el.attributes.packet_price)}
                                      </p>
                                      <p className="text-sm text-black dark:text-white">
                                        /{el.attributes.packet_monthly_period}{" "}
                                        bulan
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {showAccordion.packet.find(
                                  (packet) =>
                                    packet.packetCode ===
                                    el.attributes.packet_code
                                )?.open ? (
                                  <Icon
                                    name={"chevronUp"}
                                    className={
                                      "h-4 w-4 text-black dark:text-white"
                                    }
                                  />
                                ) : (
                                  <Icon
                                    name={"chevronDown"}
                                    className={
                                      "h-4 w-4 text-black dark:text-white"
                                    }
                                  />
                                )}
                              </div>
                              {showAccordion.packet.find(
                                (packet) =>
                                  packet.packetCode ===
                                  el.attributes.packet_code
                              )?.open && (
                                <>
                                  <ul className="text-sm list-disc text-gray-500 dark:text-gray-300">
                                    {el.attributes.packet_additional_info.facilities.map(
                                      (el, index) => (
                                        <li
                                          key={index}
                                          className="flex items-center text-xs"
                                        >
                                          <Icon
                                            className={"h-6 w-6"}
                                            name={"check"}
                                          />
                                          {el.desc}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                  <Button
                                    type={
                                      el.attributes.packet_additional_info
                                        .isFree1Mnt
                                        ? "fill"
                                        : "outline"
                                    }
                                    size={"small"}
                                    onClick={() =>
                                      navigate("/form-registration", {
                                        state: {
                                          detailWebsite: {
                                            websiteName:
                                              detailTemplate.attributes
                                                .website_name,
                                            id: detailTemplate.id,
                                          },
                                          detailPacket: {
                                            packetName:
                                              el.attributes.packet_name,
                                            packetPeriod:
                                              el.attributes
                                                .packet_monthly_period,
                                            id: el.id,
                                          },
                                        },
                                      })
                                    }
                                    className={
                                      "flex w-full justify-center font-medium items-center gap-2 "
                                    }
                                  >
                                    {el.attributes.packet_additional_info
                                      .isFree1Mnt
                                      ? "Coba Sekarang"
                                      : "Pilih Paket Langganan"}{" "}
                                    <Icon
                                      className={"h-5 w-5"}
                                      name={"arrowRight"}
                                    />
                                  </Button>
                                </>
                              )}
                            </div>

                            {el.attributes.packet_additional_info.isFree1Mnt &&
                              detailTemplate.attributes.template_website_packets
                                .data.length > 1 && (
                                <div className="flex items-center">
                                  <span className="border-b border-gray-200 w-full"></span>
                                  <p className="flex flex-shrink-0 text-gray-500 dark:text-gray-200 text-sm">
                                    atau pilih paket langganan
                                  </p>
                                  <span className="border-b border-gray-200 w-full"></span>
                                </div>
                              )}
                          </div>
                        )
                      )}
                      <div className="flex items-center px-4 py-2 gap-1  flex-wrap">
                        <p className="text-sm text-black dark:text-white">
                          Ingin tau detail Website atau memilki kebutuhan
                          khusus?
                        </p>
                        <Button
                          type={"link"}
                          size={"small"}
                          className={"!p-0"}
                          onClick={() => handleNeedHelp()}
                        >
                          Hubungi Kami
                        </Button>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Layout>
      <Footer />

      {showModal.share.show && (
        <Modal>
          <div className="xl:rounded-lg rounded-b-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-slate-800 z-30 font-custom">
            <div className="px-4 py-2 flex flex-col gap-2 w-full">
              <h3 className="text-xl font-semibold text-black dark:text-white">
                Yuk bagikan Website {detailTemplate.attributes.website_name}
              </h3>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center gap-3 divide-x w-full">
                  <div className="flex items-center gap-2 w-full">
                    <input
                      type={"text"}
                      value={showModal.share.url}
                      readOnly
                      className="px-4 py-2 rounded bg-gray-100 border border-gray-300 font-custom text-gray-500 w-full"
                    />

                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(showModal.share.url);
                        setCopy(!copy);
                      }}
                      type={"outline"}
                      size={"medium"}
                      className={`!rounded-lg !relative ${
                        copy && "bg-blue-50"
                      }`}
                    >
                      Salin
                      {copy && (
                        <Tooltip
                          message={"Tersalin!"}
                          className={"top-8"}
                          type={"click"}
                          isVisible={copy}
                          setIsVisible={setCopy}
                        />
                      )}
                    </Button>
                  </div>
                  <Tooltip
                    className={"top-10"}
                    type={"hover"}
                    message={"Bagikan ke Whatsapp"}
                  >
                    <Button
                      type={"icon"}
                      className="!p-1 "
                      onClick={() =>
                        handleShareWebsite(
                          detailTemplate.attributes.website_name,
                          showModal.share.url
                        )
                      }
                    >
                      <Icon name={"whatsapp"} className={"h-12 w-12"} />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end p-4 gap-2">
              <Button
                type={"outline"}
                size={"medium"}
                onClick={() =>
                  setShowModal({
                    ...showModal,
                    share: {
                      show: !showModal.share.show,
                      url: window.location.href,
                    },
                  })
                }
              >
                Tutup
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DetailTemplate;
