import React from "react";

const Badges = ({ handleClick, size, className, children, active }) => {
  switch (size) {
    case "large":
      return (
        <p
          onClick={handleClick}
          className={`${className} ${
            active ? "bg-blue-500 text-white" : "text-black"
          } rounded-full flex items-center justify-center px-4 py-2 text-lg font-custom border`}
        >
          {children}
        </p>
      );
    case "medium":
      return (
        <p
          onClick={handleClick}
          className={`${className} ${
            active
              ? "bg-gradient-to-r from-cyan-500 via-sky-500 to-blue-500 text-white"
              : "text-black dark:text-white"
          } rounded-full flex items-center justify-center px-3 py-1 font-custom border cursor-pointer`}
        >
          {children}
        </p>
      );
    case "small":
      return (
        <p
          onClick={handleClick}
          className={`${className} ${
            active ? "bg-blue-500 text-white" : "bg-gray-200 text-black"
          } rounded-full flex items-center justify-center px-2 text-sm font-custom border`}
        >
          {children}
        </p>
      );

    default:
      <p
        className={`${className} flex items-center justify-center px-3 py-1 font-custom bg-gray-300 rounded border border-gray-500`}
      >
        {children}
      </p>;
  }
};

export default Badges;
