import React from "react";
import Icon from "../../Assets/Icon/Icon";

const Stepper = ({ currentStep, steps }) => {
  return (
    <div className="flex items-center gap-2">
      {steps.map((step, index) => (
        <div key={index} className={`flex items-center gap-1`}>
          <div
            className={`flex items-center justify-center w-7 h-7 rounded-full font-custom text-sm ${
              currentStep >= index
                ? "bg-gradient-to-r from-cyan-500 via-sky-500 to-blue-500 text-white"
                : "text-gray-500 bg-gray-400/20 dark:bg-white/30 dark:text-white"
            }`}
          >
            {index + 1}
          </div>
          {currentStep === index && (
            <div className="font-custom text-sm text-black dark:text-white">{step}</div>
          )}
          {index < steps.length - 1 && (
            <Icon className={"h-4 w-4 dark:text-white text-black"} name={"chevronRight"} />
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
