import React, { useContext } from "react";
import Icon from "../../Assets/Icon/Icon";
import { ContentContext } from "../../Context/ContentProvider";
import Label from "../Label/Label";
import Layout from "../Layout/Layout";
import step from "../../Assets/Image/step.svg";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";

const StepToIntegrate = () => {
  const { content, loadingContent } = useContext(ContentContext);
  const navigate = useNavigate();
  return (
    <Layout>
      {loadingContent ? (
        <div className="w-full h-[400px] bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
      ) : (
        <div className="py-4 text-center">
          <Label
            type={"section-title"}
            className={"text-black dark:text-white"}
          >
            {content.data.attributes.how_to_integrate.description_header}
          </Label>
          <div className="xl:grid xl:grid-cols-2 xl:p-8 p-2 rounded-2xl mt-6 xl:w-5/6 w-full mx-auto bg-slate-50 dark:bg-slate-700 gap-10 relative">
            <div className="space-y-28 z-20">
              {content.data.attributes.how_to_integrate.description_detail.map(
                (el, index) => (
                  <div
                    key={index}
                    className="flex items-start space-x-2 relative"
                  >
                    <div className="flex flex-col items-center ">
                      <div className="p-3 flex rounded-full bg-gradient-to-r from-cyan-500 via-sky-500 to-blue-500">
                        <p className="h-10 w-10 text-3xl font-semibold font-custom flex justify-center items-center text-white">
                          {index + 1}
                        </p>
                      </div>
                      {index <
                        content.data.attributes.how_to_integrate
                          .description_detail.length -
                          1 && (
                        <div className="absolute top-[86px] border h-full border-blue-300 "></div>
                      )}
                    </div>
                    <div className="flex-1 z-20">
                      <div className="flex flex-col items-start justify-start h-full font-custom">
                        <p className="ml-2 text-2xl font-medium text-start text-black dark:text-white">
                          {el.desc}
                        </p>
                        <p className="ml-2 text-gray-500 text-start dark:text-gray-200">
                          {el.subdesc}
                        </p>
                        {index === 0 && (
                          <Button
                            type={"fill"}
                            size={"medium"}
                            onClick={() => navigate("/template-websites")}
                            className={
                              "flex items-center w-fit gap-2 hover:bg-gradient-to-r hover:from-cyan-600 hover:via-sky-600 hover:to-blue-600 hover:shadow-xl"
                            }
                          >
                            Lihat semua template{" "}
                            <Icon className={"h-5 w-5"} name={"arrowRight"} />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="w-full items-center z-20 xl:flex hidden">
              <img src={step} alt="step" className="flex max-w-full" />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              className="absolute bottom-0 w-full rounded-b-2xl fill-slate-100 dark:fill-slate-700"
            >
              <path d="M0 192l48 5.3c48 5.7 144 15.7 240-10.6C384 160 480 96 576 74.7c96-21.7 192 .3 288 32 96 32.3 192 74.3 288 64C1248 160 1344 96 1392 64l48-32v288H0z"></path>
            </svg>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default StepToIntegrate;
