import React, { useRef } from "react";
import ContactUs from "../../Components/ContactUs/ContactUs";
import DescriptionHomePage from "../../Components/DescriptionHomePage/DescriptionHomePage";
import Footer from "../../Components/Footer/Footer";
import Heroimage from "../../Components/Heroimage/Heroimage";
import Navbar from "../../Components/Navbar/Navbar";
import StepToIntegrate from "../../Components/StepToIntegrate/StepToIntegrate";
import WowTemplate from "../../Components/Templates/WowTemplate";

const Home = () => {
  const myRef = useRef();
  const scrollToDetailDesc = () =>
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  return (
    <>
      <Navbar />
      <div className="bg-white dark:bg-gradient-to-b dark:from-slate-900 dark:to-slate-800 transition-colors duration-300">
        <Heroimage scrollToDetailDesc={scrollToDetailDesc} />
      </div>
      <div className="bg-slate-50 dark:bg-slate-900 transition-colors duration-300">
        <WowTemplate />
      </div>
      <div className="bg-gradient-to-b from-slate-50 via-sky-100 to-white dark:from-slate-900 dark:via-sky-900 dark:to-slate-800 transition-colors duration-300">
        <DescriptionHomePage myRef={myRef} />
      </div>
      <div className="bg-white dark:bg-slate-800 transition-colors duration-300">
        <StepToIntegrate />
      </div>
      <div className="bg-gradient-to-br from-cyan-500 via-sky-500 to-blue-500 text-white">
        <ContactUs />
      </div>
      <Footer />
    </>
  );
};

export default Home;
