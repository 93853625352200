import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../Assets/Icon/Icon";
import Logo from "../../Assets/Icon/Logo";
import { useFavorites } from "../../Context/FavoritesProvider";
import { ScrollContext } from "../../Context/ScrollProvider";
import { getErrorMessage } from "../../Utils/errorUtils";
import Button from "../Button/Button";
import ThemeToggle from "../Button/ThemeToggle";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import SearchBar from "../Input/SearchBar";
import Layout from "../Layout/Layout";
import Menu from "../Menu/Menu";
import SideMenu from "../Menu/SideMenu";
import Modal from "../Modal/Modal";

const Navbar = () => {
  const [showExplore, setShowExplore] = useState({
    templateWebsite: false,
  });
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [filterTemplate, setFilterTemplate] = useState({
    data: [
      {
        attributes: {
          desc: "",
          slug: "",
          name: "",
        },
      },
    ],
  });

  const { favorites } = useFavorites();
  const isScrolled = useContext(ScrollContext);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavbar = async () => {
    setLoadingFilter(true);
    setError(null);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/template-website-categories?populate=*`
        )
        .then((val) => {
          setFilterTemplate(val.data);
        });
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setLoadingFilter(false);
    }
  };

  useEffect(() => {
    handleNavbar();
  }, []);

  return (
    <>
      <nav
        className={`py-4 font-custom sticky top-0 z-30 dark:bg-slate-900 transition-colors duration-300 ${
          isScrolled &&
          "shadow-lg backdrop-blur-sm bg-white/30 dark:bg-white/30"
        }`}
      >
        <Layout>
          <div className="flex items-center justify-between w-full">
            <ul className="flex items-center space-x-10">
              <li>
                <a href="/" className="items-center">
                  <Logo />
                </a>
              </li>
              <li className="relative hidden xl:flex">
                <div
                  onMouseEnter={() =>
                    setShowExplore({
                      ...showExplore,
                      templateWebsite: !showExplore.templateWebsite,
                    })
                  }
                  onMouseLeave={() =>
                    setShowExplore({
                      ...showExplore,
                      templateWebsite: !showExplore.templateWebsite,
                    })
                  }
                  className={"flex items-center gap-1 cursor-pointer "}
                >
                  <span
                    className="text-black text-2xl font-semibold dark:text-white"
                    onClick={() => navigate("/template-websites")}
                  >
                    Website
                  </span>

                  {showExplore.templateWebsite ? (
                    <Icon
                      name={"chevronUp"}
                      className={"h-5 w-5 text-black dark:text-white"}
                    />
                  ) : (
                    <Icon
                      name={"chevronDown"}
                      className={"h-5 w-5 text-black dark:text-white"}
                    />
                  )}
                  {showExplore.templateWebsite && (
                    <>
                      {error !== null ? (
                        <div className="flex flex-col absolute top-full w-max shadow-xl: bg-white  rounded-lg">
                          <ul className="p-2 space-y-1 divide-y">
                            <ErrorHandler
                              error={error}
                              onRetry={handleNavbar}
                            />
                          </ul>
                        </div> //error handle
                      ) : (
                        <>
                          {loadingFilter ? (
                            <ul className="gap-2">
                              <li className="h-6 w-full bg-slate-200 dark:bg-slate-600 rounded-lg"></li>
                              <li className="h-6 w-full bg-slate-200 dark:bg-slate-600 rounded-lg"></li>
                            </ul>
                          ) : (
                            <div className="flex flex-col absolute top-full w-max shadow-xl bg-white dark:bg-slate-900 rounded-lg">
                              <ul className="p-2 space-y-1 divide-y">
                                {filterTemplate.data.map((el, index) => (
                                  <Menu
                                    key={index}
                                    item={el}
                                    showExplore={showExplore}
                                    setShowExplore={setShowExplore}
                                  />
                                ))}
                                <li className="hover:no-underline hover:bg-gray-100 dark:hover:bg-slate-900 hover:rounded-xl hover:w-full p-2">
                                  <Button
                                    type={"link"}
                                    size={"medium"}
                                    onClick={() => {
                                      navigate("/template-websites");
                                      setShowExplore({
                                        ...showExplore,
                                        video: false,
                                      });
                                    }}
                                    className={`!flex py-1 !items-center !gap-2 !font-semibold hover:!no-underline !text-black dark:!text-white`}
                                  >
                                    Lihat Semua{" "}
                                    <Icon
                                      name={"arrowRight"}
                                      className={"h-4 w-4"}
                                    />
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </li>

              <li className="relative hidden xl:flex">
                <div className={"flex items-center gap-1 cursor-pointer "}>
                  <span
                    className="text-black text-2xl font-semibold dark:text-white"
                    onClick={() => navigate("/FAQ")}
                  >
                    FAQ
                  </span>
                </div>
              </li>
            </ul>
            <ul className="items-center gap-4 flex">
              <li className="relative hidden xl:flex">
                <Button
                  type={`icon`}
                  className={`!p-0 !flex `}
                  onClick={() => setShowSearchBar(true)}
                >
                  <div className="rounded-full p-3 flex items-center justify-center backdrop-blur-sm bg-gradient-to-br from-slate-100 to-blue-200 hover:bg-blue-300">
                    <Icon name={"search"} className={"h-5 w-5 text-black"} />
                  </div>
                </Button>
              </li>

              <li>
                <Button
                  type={`icon`}
                  className={`!p-0 !relative !hidden xl:!flex`}
                  onClick={() => navigate("/favorite")}
                >
                  {favorites.length > 0 && (
                    <span className="absolute flex h-3 w-3 rounded-full bg-blue-400 top-0 right-0 z-10"></span>
                  )}
                  <div
                    className={`rounded-full relative inline-flex  p-3  items-center justify-center backdrop-blur-sm bg-gradient-to-br from-slate-100 to-blue-200 hover:bg-blue-300 `}
                  >
                    <Icon name={"heart"} className={"h-5 w-5 text-black"} />
                  </div>
                </Button>
              </li>
              <li className="relative xl:hidden">
                <Button
                  type={`icon`}
                  className={`!p-0 !flex `}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <div className="rounded-lg p-2 flex items-center justify-center backdrop-blur-sm bg-gradient-to-br from-slate-100 to-blue-200 hover:bg-blue-300">
                    <Icon name={"list"} className={"h-8 w-8 text-black"} />
                  </div>
                </Button>
              </li>
              <li className="xl:block hidden">
                <ThemeToggle />
              </li>
            </ul>
          </div>
        </Layout>
      </nav>
      {showSearchBar && (
        <Modal
        // handleClose={() => setShowSearchBar(false)}
        >
          <div className="flex flex-col w-full">
            <div className="flex w-full justify-end">
              <Button
                type={"icon"}
                className={"!p-0 !text-white"}
                onClick={() => setShowSearchBar(false)}
              >
                <Icon name={"x"} className={"h-10 w-10"} />
              </Button>
            </div>
            <SearchBar
              className={"!drop-shadow-lg"}
              handleClose={() => setShowSearchBar(false)}
            />
          </div>
        </Modal>
      )}
      <SideMenu
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        showExplore={showExplore}
        setShowExplore={setShowExplore}
        loadingFilter={loadingFilter}
        error={error}
        handleNavbar={handleNavbar}
        filterTemplate={filterTemplate}
        setShowSearchBar={setShowSearchBar}
      />
    </>
  );
};

export default Navbar;
