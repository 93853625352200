import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../Assets/Icon/Icon";
import Button from "../Button/Button";
import Label from "../Label/Label";
import Layout from "../Layout/Layout";
import { ContentContext } from "../../Context/ContentProvider";

const Heroimage = ({ scrollToDetailDesc }) => {
  const navigate = useNavigate();
  const { content, loadingContent } = useContext(ContentContext);
  return (
    <div className="relative">
      <Layout className={"!z-20"}>
        <div className="xl:gap-4 relative w-full flex flex-col xl:justify-center xl:text-center text-center space-y-8 xl:py-10 py-1 z-20">
          {loadingContent ? (
            <div className="flex flex-col gap-2 z-20">
              <div className="w-full h-10 bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
              <div className="w-full h-5 bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
              <div className="w-full h-4 bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
            </div>
          ) : (
            <div className="flex flex-col gap-1 w-5/6 justify-center items-center mx-auto">
              <Label type={"headline"}>
                Temukan{" "}
                <span className=" text-blue-500 font-extrabold">Website</span>{" "}
                Impian Anda di{" "}
                <span className="bg-gradient-to-r from-cyan-200 via-sky-500 to-blue-700 text-transparent bg-clip-text animate-gradient bg-300% font-extrabold">
                  WOW Template
                </span>
              </Label>
              <Label
                type={"headline-description"}
                className={
                  "text-gray-600 dark:text-gray-300 leading-snug xl:!text-3xl !text-xl "
                }
              >
                {content.data.attributes.subheadline}
              </Label>
            </div>
          )}
          <div className="flex xl:flex-row flex-col justify-center items-center gap-2">
            <Button
              type={"fill"}
              size={"medium"}
              onClick={() => navigate("/template-websites")}
              className={
                "flex items-center w-fit gap-3 !font-semibold !text-xl text hover:bg-gradient-to-r hover:from-cyan-600 hover:via-sky-600 hover:to-blue-600"
              }
            >
              Cari Website Sekarang{" "}
              <Icon className={"h-5 w-5"} name={"search"} />
            </Button>
            <Label
              type={"card-price"}
              className={"dark:text-white !text-lg !text-gray-500"}
            >
              atau
            </Label>
            <Button
              type={"link"}
              onClick={scrollToDetailDesc}
              className={`!flex !items-center !gap-1 !p-0 !font-semibold !text-xl text-blue-500 hover:underline`}
            >
              Pelajari lebih lanjut{" "}
              <Icon className={"h-7 w-7"} name={"arrowRight"} />
            </Button>
          </div>
        </div>
      </Layout>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="absolute bottom-0 w-full z-0 fill-slate-50 dark:fill-slate-900 duration-300 transition-colors"
      >
        <path
          // fill="#f8fafc"
          d="M0 192l48 5.3c48 5.7 144 15.7 240-10.6C384 160 480 96 576 74.7c96-21.7 192 .3 288 32 96 32.3 192 74.3 288 64C1248 160 1344 96 1392 64l48-32v288H0z"
        ></path>
      </svg>
    </div>
  );
};

export default Heroimage;
