import React from "react";
import Icon from "../../Assets/Icon/Icon";

const InputWebsite = ({
  formData,
  handleChange,
  handleSearchSubDomain,
  errors,
  valid,
  loadingSearchSubdomain,
  searchSubDomain,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <label className="block text-gray-700 font-custom dark:text-white">
          Nama Subdomain*
        </label>

        <div className="flex items-center gap-1">
          <input
            type="text"
            name="subdomainName"
            value={formData.subdomainName}
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder="Nama Subdomain"
            className={`w-full  px-4 py-2 border rounded-lg focus:outline-none ${
              errors.subdomainName
                ? "border-red-500"
                : valid.subdomainName
                ? "border-blue-500"
                : "border-gray-300"
            }`}
          />
          <button
            type="button"
            disabled={formData.subdomainName.length === 0}
            onClick={() => handleSearchSubDomain()}
            className="flex items-center justify-center bg-sky-500 px-4 py-2 rounded-lg text-white"
          >
            Cari
          </button>
        </div>
        {loadingSearchSubdomain ? (
          <p className="flex text-sm">Mengecek ketersediaan subdomain...</p>
        ) : (
          <>
            {searchSubDomain.searchResult === true ? (
              <p className="flex items-center gap-1 text-blue-500 font-custom text-xs w-fit">
                {searchSubDomain.searchValue}.wow-template.com bisa digunakan{" "}
                <Icon className={"h-6 w-6"} name={"check"} />
              </p>
            ) : searchSubDomain.searchResult === false &&
              errors.subdomainName === true ? (
              <p className="flex items-center text-red-500 font-custom text-xs w-fit">
                {searchSubDomain.searchValue}.wow-template.com sudah digunakan{" "}
                <Icon className={"h-6 w-6"} name={"x"} />
              </p>
            ) : (
              ""
            )}
          </>
        )}
        <label className="flex items-center gap-1 text-blue-500 text-xs font-custom bg-blue-50 border border-blue-500 rounded-md px-2 py-1">
          <Icon className={"h-6 w-6"} name={"info-circle"} /> Subdomain agar
          website Anda dapat diakses, contoh: usahaku..wow-template.com
        </label>
      </div>
      <div>
        <label className="block text-gray-500 font-custom dark:text-white">
          Pilihan Website
        </label>
        <input
          type="text"
          name="selectedWebsite"
          value={formData.selectedWebsite.websiteName}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-lg bg-gray-300 border-gray-300 cursor-not-allowed"
          disabled
        />
      </div>

      <div>
        <label className="block text-gray-700 font-custom dark:text-white">
          Pilihan Paket
        </label>
        <input
          type="text"
          name="selectedPacket"
          value={formData.selectedPacket.packetName}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-lg bg-gray-300 border-gray-300 cursor-not-allowed"
          disabled
        />
      </div>
      <div>
        <label className="block text-gray-700 font-custom dark:text-white">
          Durasi (Bulan)
        </label>
        <input
          type="text"
          name="selectedPeriod"
          value={formData.selectedPacket.packetPeriod}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-lg bg-gray-300 border-gray-300 cursor-not-allowed"
          disabled
        />
      </div>
    </div>
  );
};

export default InputWebsite;
