import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../Assets/Icon/Icon";
import { getErrorMessage } from "../../Utils/errorUtils";
import Button from "../Button/Button";
import Card from "../Card/Card";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import Label from "../Label/Label";
import Layout from "../Layout/Layout";

const WowTemplate = () => {
  const [website, setWebsite] = useState([]);
  const [loadingWebsite, setLoadingWebsite] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleRequestWebsite = async () => {
    setLoadingWebsite(true);
    setError(null);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/template-websites?sort[0]=website_count_sold:desc&pagination[page]=1&pagination[pageSize]=6&populate=*`
        )
        .then((val) => {
          setWebsite(val.data.data);
        });
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setLoadingWebsite(false);
    }
  };

  useEffect(() => {
    handleRequestWebsite();
  }, []);

  return (
    <Layout className={"pb-6 pt-6"}>
      <Label
        type={"section-title"}
        className={
          "flex items-center justify-center xl:justify-start gap-1 dark:text-white"
        }
      >
        Website Terpopuler
      </Label>
      {error !== null ? (
        <ErrorHandler error={error} onRetry={handleRequestWebsite} /> //error handle
      ) : (
        <>
          {loadingWebsite ? (
            <div className="grid xl:grid-cols-3 grid-cols-2 gap-4 mt-4 animate-pulse">
              {Array.from(Array(6), (e, i) => (
                <div
                  key={i}
                  className="w-full h-[320px] rounded-lg bg-slate-200 animate-pulse dark:bg-slate-600"
                ></div>
              ))}
            </div>
          ) : (
            <>
              {website.length > 0 ? (
                <div className="grid xl:grid-cols-3 grid-cols-1 gap-4 mt-4">
                  {website?.map((val, index) => (
                    <Card
                      route={`/template-websites/${val.attributes.slug}`}
                      id={val.id}
                      key={index}
                      category={
                        val.attributes.template_website_category.data.attributes
                          .desc
                      }
                      images={val.attributes.website_image.data}
                      title={val.attributes.website_name}
                      description={val.attributes.website_description}
                      slug={val.attributes.slug}
                      price={val.attributes.website_start_price}
                    />
                  ))}
                </div>
              ) : (
                <div className="w-full flex flex-col items-center gap-1 mt-4 dark:text-white">
                  <Icon name={"info-circle"} className={"h-8 w-8"} />
                  <p className="w-full font-semibold text-center font-custom">
                    Belum ada template
                  </p>
                  <p className="w-full text-sm text-center font-custom text-gray-500">
                    Nantikan template terbaik dari kami
                  </p>
                </div>
              )}
            </>
          )}
          {website.length > 0 && (
            <div className="flex w-full justify-center mt-6">
              <Button
                type={"outline"}
                size={"medium"}
                onClick={() => navigate("/template-websites")}
              >
                Lihat Website Lainnya
              </Button>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default WowTemplate;
