import React from "react";
import Icon from "../../Assets/Icon/Icon";
import Maintenance from "../../Assets/Image/Maintenance.svg";

const ComingSoon = () => {
  return (
    <div className="h-screen xl:max-w-2xl mx-auto flex flex-col justify-center items-center gap-1 text-center">
      <img src={Maintenance} alt="maintenance" className="max-w-[150px]" />
      <p className="text-4xl py-2 font-custom font-bold bg-gradient-to-r from-cyan-200 via-sky-500 to-blue-700 text-transparent bg-clip-text animate-gradient bg-300%">
        Kami sedang mempersiapkan Website terbaik untuk Anda
      </p>
      <p className="text-sm font-custom">
        Ikuti sosial media kami untuk update terbaru lainnya
      </p>
      <ul className="flex items-start gap-2">
        <li>
          <a
            href="https://instagram.com/wow_template"
            className="flex flex-col items-center"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon name={"instagram"} className={"h-7 w-7"} />
            <p className="font-custom text-sm">@wow_template</p>
          </a>
        </li>
        {/* <li className="flex flex-col items-center">
          <a
            href="https://youtube.com"
            className="flex flex-col items-center"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon name={"youtube"} className={"h-7 w-7"} />
            <p className="font-custom text-sm">WOW Template</p>
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default ComingSoon;
