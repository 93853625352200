import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";

const Breadcrumb = ({ categoryName, categoryPath }) => {
  const navigate = useNavigate();
  const crumbs = [
    { name: "Beranda", path: "/" },
    { name: "Website", path: "/template-websites" },
    { name: categoryName, path: `/template-websites${categoryPath}` },
  ];
  return (
    <div className="flex space-x-2 text-gray-700 overflow-hidden">
      {crumbs.map((crumb, index) => (
        <span key={index} className="flex items-center space-x-2">
          {index !== 0 && <span className="dark:text-white">/</span>}
          <Button
            type={"link"}
            onClick={() => navigate(crumb.path)}
            className={"!p-0 !font-normal"}
            size={"large"}
          >
            {crumb.name}
          </Button>
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
