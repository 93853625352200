import React from "react";

const InputIdentity = ({ formData, handleChange, errors, valid }) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <label className="block text-gray-700 font-custom dark:text-white">
          Nama Lengkap*
        </label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Nama Lengkap"
          className={`w-full  px-4 py-2 border rounded-lg focus:outline-none focus:bg-white bg-gray-50 ${
            errors.name
              ? "border-red-500"
              : valid.name
              ? "border-blue-500"
              : "border-gray-300"
          }`}
        />
        {errors.name && (
          <p className="text-red-500 text-sm font-custom">{errors.name}</p>
        )}
      </div>
      <div>
        <label className="block text-gray-700 font-custom dark:text-white">
          No. Whatsapp*
        </label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="No. Whatsapp"
          className={`w-full  px-4 py-2 border rounded-lg focus:outline-none focus:bg-white bg-gray-50 ${
            errors.phoneNumber
              ? "border-red-500"
              : valid.phoneNumber
              ? "border-blue-500"
              : "border-gray-300"
          }`}
        />
        {errors.phoneNumber && (
          <p className="text-red-500 text-sm font-custom">
            {errors.phoneNumber}
          </p>
        )}
      </div>
      <div>
        <label className="block text-gray-700 font-custom dark:text-white">
          Email*
        </label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          className={`w-full  px-4 py-2 border rounded-lg focus:outline-none bg-gray-50 focus:bg-white ${
            errors.email
              ? "border-red-500"
              : valid.email
              ? "border-blue-500"
              : "border-gray-300"
          }`}
        />
        {errors.email && (
          <p className="text-red-500 text-sm font-custom">{errors.email}</p>
        )}
      </div>
    </div>
  );
};

export default InputIdentity;
