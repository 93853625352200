import React, { useState } from "react";
import Label from "../Label/Label";
import Icon from "../../Assets/Icon/Icon";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import Tooltip from "../Tooltip/Tooltip";
import CardImageSlider from "../ImageSlider/CardImageSlider";
import Modal from "../Modal/Modal";
import useGetCurrency from "../../Hooks/useGetCurrency";
import Badges from "../Badges/Badges";

const Card = ({ images, title, category, route, id, slug, price }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({
    share: { show: false, url: "" },
  });
  const [copy, setCopy] = useState(false);
  const currency = useGetCurrency();

  const handleShareWebsite = (website_name, link) => {
    const message = `Hai, ini adalah Website ${website_name}, untuk detailnya kamu dapat lihat di link ini  ${link}.`;
    const whatsappUrl = `https://wa.me?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };

  return (
    <>
      <div className="flex flex-col bg-white font-custom border rounded-xl shadow-md hover:shadow-2xl dark:bg-white/10 dark:border-slate-900 dark:text-white">
        <CardImageSlider
          images={images}
          id={id}
          handleViewDetail={() => navigate(route)}
          handleShowModal={() =>
            setShowModal({
              ...showModal,
              share: {
                show: !showModal.share.show,
                url: process.env.REACT_APP_HREF + `/template-websites/${slug}`,
              },
            })
          }
          slug={slug}
        />
        <div className="flex flex-col p-4">
          <div className="flex flex-col gap-2 h-full justify-between">
            <Badges
              size={"medium"}
              className={
                "bg-blue-50 border-blue-200 text-blue-400 dark:!text-blue-500 border rounded-full py-0 w-fit"
              }
            >
              {category}
            </Badges>
            <Label type={"card-title"}>{title}</Label>
            <div className="flex justify-between items-end">
              <span className="font-custom gap-1">
                <p className="text-sm font-extralight">Mulai dari</p>
                <div className="flex items-end gap-1">
                  <p className="text-xl font-semibold">{currency(price)}</p>
                  <p className="text-sm font-extralight">/bulan</p>
                </div>
              </span>
              <Button
                type={"link"}
                size={"medium"}
                className={"!p-0 flex items-center gap-1"}
                onClick={() => navigate(route)}
              >
                Explore <Icon name={"arrowRight"} className={"h-4 w-4"} />
              </Button>
            </div>
          </div>
          <div className="flex w-full items-center">
            <div className="flex flex-col gap-1"></div>
          </div>
        </div>
      </div>

      {showModal.share.show && (
        <Modal>
          <div className="xl:rounded-lg rounded-b-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-slate-800 z-30 font-custom">
            <div className="px-4 py-2 flex flex-col gap-2 w-full">
              <h3 className="text-xl font-semibold text-black dark:text-white">
                Yuk bagikan Website {title}
              </h3>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center gap-3 divide-x w-full">
                  <div className="flex items-center gap-2 w-full">
                    <input
                      type={"text"}
                      value={showModal.share.url}
                      readOnly
                      className="px-4 py-2 rounded bg-gray-100 border border-gray-300 font-custom text-gray-500 w-full"
                    />

                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(showModal.share.url);
                        setCopy(!copy);
                      }}
                      type={"outline"}
                      size={"medium"}
                      className={`!rounded-lg !relative ${
                        copy && "bg-blue-50"
                      }`}
                    >
                      Salin
                      {copy && (
                        <Tooltip
                          message={"Tersalin!"}
                          className={"top-8"}
                          type={"click"}
                          isVisible={copy}
                          setIsVisible={setCopy}
                        />
                      )}
                    </Button>
                  </div>
                  <Tooltip
                    className={"top-10"}
                    type={"hover"}
                    message={"Bagikan ke Whatsapp"}
                  >
                    <Button
                      type={"icon"}
                      className="!p-1"
                      onClick={() =>
                        handleShareWebsite(title, showModal.share.url)
                      }
                    >
                      <Icon name={"whatsapp"} className={"h-12 w-12"} />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end p-4 gap-2">
              <Button
                type={"outline"}
                size={"medium"}
                onClick={() =>
                  setShowModal({
                    ...showModal,
                    share: {
                      show: !showModal.share.show,
                      url: window.location.href,
                    },
                  })
                }
              >
                Tutup
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Card;
