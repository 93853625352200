import React, { useContext, useEffect, useState } from "react";
import Icon from "../../Assets/Icon/Icon";
import Button from "../../Components/Button/Button";
import Stepper from "../../Components/Stepper/Stepper";
import Result from "./Result";
import InputIdentity from "./InputIdentity";
import InputWebsite from "./InputWebsite";
import Confirmation from "./Confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ThemeToggle from "../../Components/Button/ThemeToggle";
import { ContentContext } from "../../Context/ContentProvider";

const steps = ["Data Diri", "Data Website", "Konfirmasi", "Selesai"];

const RegistrationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    subdomainName: "",
    selectedWebsite: { id: "", websiteName: "" },
    selectedPacket: {
      packetName: "",
      packetPeriod: "",
      id: "",
    },
    termsAccepted: false,
  });
  const [errors, setErrors] = useState({});
  const [errorSubmit, setErrorSubmit] = useState([]);
  const [valid, setValid] = useState({});
  const [resultSubmit, setResultSubmit] = useState({
    attributes: {
      order_id: "",
    },
  });
  const { content, loadingContent } = useContext(ContentContext);
  const [searchSubDomain, setSearchSubDomain] = useState({
    searchValue: "",
    searchResult: "",
  });
  const [loadingSearchSubdomain, setLoadingSearchSubdomain] = useState(false);
  const [loadingPostOrder, setLoadingPostOrder] = useState(false);
  const [showTnc, setShowTnc] = useState(false);

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const validate = (name, value) => {
    let error = "";
    let isValid = false;

    if (name === "name") {
      if (!value.trim()) {
        error = "Nama harus diisi";
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        error = "Nama harus diisi dengan huruf";
      } else {
        isValid = true;
      }
    }

    if (name === "phoneNumber") {
      if (!value.trim()) {
        error = "No. Whatsapp harus diisi";
      } else if (!/^08\d+$/.test(value)) {
        error =
          "Nomor Whatsapp harus diawali dengan '08' dan harus diisi dengan angka";
      } else {
        isValid = true;
      }
    }

    if (name === "email") {
      if (!value.trim()) {
        error = "Email harus diisi";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        error = "Format email tidak valid";
      } else {
        isValid = true;
      }
    }

    if (name === "subdomainName") {
      if (!value.trim()) {
        error = "Subdomain is a mandatory field";
      } else if (!/^[a-zA-Z-]+$/.test(value)) {
        error = "Subdomain should contain only letters and hyphens";
      } else if (value.length < 4) {
        error = "Subdomain should be at least 4 characters long";
      } else {
        isValid = true;
      }
    }

    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));

    setValid((prev) => ({
      ...prev,
      [name]: isValid,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let processedValue = type === "checkbox" ? checked : value;

    if (name === "subdomainName") {
      processedValue = value.toLowerCase().replace(/\s+/g, "-"); // Replace spaces with hyphens
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: processedValue,
    }));
    validate(name, processedValue);
  };

  useEffect(() => {
    if (location.state) {
      setFormData((prev) => ({
        ...prev,
        selectedWebsite: {
          id: location.state.detailWebsite.id,
          websiteName: location.state.detailWebsite.websiteName,
        },
        selectedPacket: {
          packetName: location.state.detailPacket.packetName,
          packetPeriod: location.state.detailPacket.packetPeriod,
          id: location.state.detailPacket.id,
        },
      }));
    }
  }, [location.state]);

  const handleCreateOrderId = () => {
    const now = new Date();
    const options = {
      timeZone: "Asia/Jakarta",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const dateFormatter = new Intl.DateTimeFormat("id-ID", options);

    const parts = dateFormatter.formatToParts(now);
    const formattedDate = parts
      .filter(
        (p) => p.type === "day" || p.type === "month" || p.type === "year"
      )
      .map((p) => p.value)
      .join("");
    const formattedTime = parts
      .filter((p) => p.type === "hour" || p.type === "minute")
      .map((p) => p.value.replace(".", ""))
      .join("");

    const paketId = location.state.detailPacket.id;
    const websiteId = location.state.detailWebsite.id;

    return `WTD${formattedDate}T${formattedTime}PID${paketId}WID${websiteId}`;
  };

  const handleSearchSubDomain = async () => {
    setLoadingSearchSubdomain(true);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/template-website-orders?filters[subdomain][$eq]=${formData.subdomainName}&populate=*`
        )
        .then((val) => {
          if (val.data.data.length !== 0) {
            setSearchSubDomain({
              ...searchSubDomain,
              searchValue: formData.subdomainName,
              searchResult: false,
            });
            setErrors((prev) => ({
              ...prev,
              subdomainName: true,
            }));
          } else {
            setSearchSubDomain({
              ...searchSubDomain,
              searchValue: formData.subdomainName,
              searchResult: true,
            });
          }
        });
      setLoadingSearchSubdomain(false);
    } catch (error) {
      console.log(error);
    }
  };

  const isStepValid = () => {
    const fieldsToCheck = {
      0: ["name", "phoneNumber", "email"],
      1: ["subdomainName"],
      2: [],
      3: [],
    };

    const allFieldsValid = fieldsToCheck[currentStep].every(
      (field) => valid[field]
    );
    if (currentStep === 2) {
      return allFieldsValid && formData.termsAccepted;
    }

    return allFieldsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingPostOrder(true);
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/template-website-orders`, {
          data: {
            full_name: formData.name,
            phone_number: formData.phoneNumber,
            email: formData.email,
            subdomain: formData.subdomainName,
            template_website_packet: formData.selectedPacket.id,
            template_website: formData.selectedWebsite.id,
            is_free_1_month: formData.selectedPacket.id === 4 ? true : false,
            terms_and_conditions: formData.termsAccepted,
            duration: formData.packetPeriod,
            status: "Request Order Diterima",
            order_id: handleCreateOrderId(),
          },
        })
        .then((response) => {
          setResultSubmit(response.data.data);
          setLoadingPostOrder(false);
          nextStep();
        })
        .catch(function (error) {
          if (error.response) {
            setErrorSubmit(error.response.data.error.details.errors);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
          setLoadingPostOrder(false);
        });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleNeedHelp = () => {
    const message = `Halo, saya ingin bertanya terkait registrasi`;
    const whatsappUrl = `https://wa.me/${
      content.data.attributes.admin_phone_number
    }?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };

  return (
    <div className="flex flex-row bg-gradient-to-br from-slate-100 via-sky-100 to-white dark:from-slate-900 dark:to-slate-800 h-screen py-2 font-custom">
      <div className="xl:w-2/5 w-full px-3 flex flex-col gap-3 mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button
              type={"icon"}
              className={"!p-2 !bg-gray-400/40 dark:!bg-white/30"}
              onClick={() => navigate(-1)}
            >
              <Icon className={"h-6 w-6 p-0 text-black"} name={"arrowLeft"} />
            </Button>
            <p className="text-xl font-semibold text-black dark:text-white">
              Registrasi WOW Template
            </p>
          </div>
          <ThemeToggle />
        </div>
        <Stepper currentStep={currentStep} steps={steps} />
        <form
          onSubmit={handleSubmit}
          className="bg-white rounded-lg px-4 py-4 h-fit shadow-md dark:bg-white/20"
        >
          {currentStep === 0 && (
            <>
              <InputIdentity
                formData={formData}
                handleChange={handleChange}
                errors={errors}
                valid={valid}
              />
              <div className="flex flex-col gap-2 mt-6">
                <Button
                  type={isStepValid() ? "fill" : "disable-fill"}
                  onClick={() => (isStepValid() ? nextStep() : "")}
                  className={"font-semibold w-full"}
                >
                  Selanjutnya
                </Button>
              </div>
            </>
          )}
          {currentStep === 1 && (
            <>
              <InputWebsite
                formData={formData}
                handleChange={handleChange}
                handleSearchSubDomain={handleSearchSubDomain}
                errors={errors}
                valid={valid}
                loadingSearchSubdomain={loadingSearchSubdomain}
                searchSubDomain={searchSubDomain}
              />
              <div className="flex flex-col gap-2 mt-6">
                <Button
                  type={
                    isStepValid() && searchSubDomain.searchResult === true
                      ? "fill"
                      : "disable-fill"
                  }
                  onClick={() => (isStepValid() ? nextStep() : "")}
                  className={"font-semibold w-full"}
                >
                  Selanjutnya
                </Button>
                <Button
                  type="outline"
                  onClick={prevStep}
                  className="font-semibold w-full"
                >
                  Kembali
                </Button>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <Confirmation
                formData={formData}
                handleChange={handleChange}
                errorSubmit={errorSubmit}
                content={content}
                showTnc={showTnc}
                setShowTnc={setShowTnc}
                loadingContent={loadingContent}
              />
              <div className="flex flex-col gap-2 mt-6">
                {loadingPostOrder ? (
                  <div className="flex w-full h-full justify-center items-center">
                    <div className="flex w-7 h-7 items-center justify-center rounded-full bg-gradient-to-tr from-cyan-500 via-sky-500 to-blue-500 animate-spin">
                      <div className="h-4 w-4 rounded-full bg-slate-50"></div>
                    </div>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className={`px-4 py-2 rounded ${
                      isStepValid()
                        ? "rounded-full font-bold focus:outline-none bg-gradient-to-r from-cyan-500 via-sky-500 to-blue-500 text-white"
                        : "rounded-full font-bold focus:outline-none bg-gradient-to-r from-cyan-300 via-sky-300 to-blue-300 text-gray-50"
                    }`}
                    disabled={!isStepValid()}
                  >
                    Submit
                  </button>
                )}
                <Button
                  type="outline"
                  onClick={prevStep}
                  className="font-semibold w-full"
                >
                  Kembali
                </Button>
              </div>
            </>
          )}
          {currentStep === 3 && (
            <Result
              formData={formData}
              order_id={resultSubmit.attributes.order_id}
            />
          )}
        </form>
        <div className="flex items-center gap-2 w-full justify-center">
          <p className="dark:text-white">Butuh bantuan?</p>
          <Button
            type="link"
            className={"!p-0"}
            onClick={() => handleNeedHelp()}
          >
            Hubungi Kami
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
