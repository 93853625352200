import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ContentContext } from "./Context/ContentProvider";
import { FavoritesProvider } from "./Context/FavoritesProvider";
import { ScrollProvider } from "./Context/ScrollProvider";
import Faq from "./Pages/Faq/Faq";
import Favorite from "./Pages/Favorite/Favorite";
import Home from "./Pages/Home/Home";
import PrivacyPolicy from "./Pages/Legal/PrivacyPolicy";
import TermsAndCondition from "./Pages/Legal/TermsAndCondition";
import NotFound from "./Pages/NotFound/NotFound";
import OrderStatus from "./Pages/RegistrationForm/OrderStatus";
import RegistrationForm from "./Pages/RegistrationForm/RegistrationForm";
import AllTemplate from "./Pages/Template/AllTemplate";
import DetailTemplate from "./Pages/Template/DetailTemplate";
import Maintenance from "./Pages/Maintenance/Maintenance";

function App() {
  const { content, loadingContent } = useContext(ContentContext);

  return (
    <>
      {loadingContent && (
        <div className="flex min-h-screen w-full items-center justify-center bg-slate-50 z-50 gap-1">
          <div className="flex w-9 h-9 items-center justify-center rounded-full bg-gradient-to-tr from-cyan-500 via-sky-500 to-blue-500 animate-spin">
            <div className="h-6 w-6 rounded-full bg-slate-50"></div>
          </div>
          <p className="text-sm font-custom">Loading...</p>
        </div>
      )}
      <>
        {content.data.attributes.maintenance ? (
          <Maintenance />
        ) : (
          <FavoritesProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <ScrollProvider>
                      <Home />
                    </ScrollProvider>
                  }
                />
                <Route
                  path="/template-websites/:slug"
                  element={
                    <ScrollProvider>
                      <DetailTemplate />
                    </ScrollProvider>
                  }
                />
                <Route
                  path="/template-websites"
                  element={
                    <ScrollProvider>
                      <AllTemplate />
                    </ScrollProvider>
                  }
                />
                <Route
                  path="/favorite"
                  element={
                    <ScrollProvider>
                      <Favorite />
                    </ScrollProvider>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <ScrollProvider>
                      <PrivacyPolicy />
                    </ScrollProvider>
                  }
                />
                <Route
                  path="/terms-and-conditions"
                  element={
                    <ScrollProvider>
                      <TermsAndCondition />
                    </ScrollProvider>
                  }
                />
                <Route
                  path="/faq"
                  element={
                    <ScrollProvider>
                      <Faq />
                    </ScrollProvider>
                  }
                />
                <Route
                  path="/form-registration"
                  element={<RegistrationForm />}
                />
                <Route
                  path="/form-registration/:order_id"
                  element={<OrderStatus />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </FavoritesProvider>
        )}
      </>
    </>
  );
}

export default App;
