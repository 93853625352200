import React, { useContext, useEffect, useState } from "react";
import Icon from "../../Assets/Icon/Icon";
import { ContentContext } from "../../Context/ContentProvider";

const Result = ({ formData, order_id }) => {
  const [countdown, setCountdown] = useState(5);
  const { content, loadingContent } = useContext(ContentContext);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      const message = `Halo, saya sudah order Website, Order # : ${order_id}. 
      Kunjungi ${process.env.REACT_APP_API_URL}/form-registration/${order_id} untuk cek status pembelian website`;
      const whatsappUrl = `https://wa.me/${
        content.data.attributes.admin_phone_number
      }?text=${encodeURIComponent(message)}`;
      window.location.href = whatsappUrl;
    }

    return () => clearInterval(timer);
  }, [
    countdown,
    formData.phoneNumber,
    content.data.attributes.admin_phone_number,
    order_id,
  ]);
  return (
    <div className="font-custom flex flex-col justify-center items-center px-9">
      {loadingContent && <p>Loading...</p>}
      <div className="flex py-8">
        <Icon name={"check-cirle"} className={"h-16 w-16 text-green-500"} />
      </div>
      <p className="text-xl font-semibold text-black dark:text-white">
        Registrasi Berhasil!
      </p>
      <p className="text-sm text-gray-600 dark:text-gray-200 text-center">
        Kamu akan diarahkan ke Whatsapp Official WOW Template untuk konfirmasi &
        pembayaran dalam {countdown} detik...
      </p>
    </div>
  );
};

export default Result;
