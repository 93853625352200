import React from "react";

const Label = ({ type, className, children }) => {
  switch (type) {
    case "headline":
      return (
        <p className={`${className} xl:text-7xl text-6xl font-bold font-custom text-black dark:text-white`}>
          {children}
        </p>
      );
    case "headline-description":
      return <p className={`${className} font-custom text-2xl`}>{children}</p>;
    case "card-title":
      return <p className={`${className} font-custom text-xl`}>{children}</p>;
    case "section-title":
      return (
        <p className={`${className} font-custom xl:text-5xl text-4xl font-bold`}>
          {children}
        </p>
      );
    case "card-price":
      return (
        <p className={`${className} font-custom font-semibold`}>{children}</p>
      );
    case "section-desc":
      return <p className={`${className} font-custom text-lg`}>{children}</p>;

    case "detail-desc":
      return <p className={`${className} font-custom`}>{children}</p>;
    case "input":
      return (
        <p className={`${className} text-lg font-semibold font-custom`}>
          {children}
        </p>
      );

    default:
      return <p className={`${className} text-4xl font-bold`}>{children}</p>;
  }
};

export default Label;
