import React from "react";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <div className="h-screen flex items-center bg-slate-50 dark:bg-slate-800 justify-center font-custom text-gray-500 dark:text-white font-semibold -mt-20">
        <div className="flex flex-col justify-center items-center gap-2 font-custom">
          <img
            width="64"
            height="64"
            src="https://img.icons8.com/color/48/clear-search.png"
            alt="clear-search"
          />
          Halaman Tidak Ditemukan
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
