import React from "react";

const Layout = ({ children, className }) => {
  return (
    <div
      className={`px-3 md:container md:mx-auto xl:container xl:mx-auto 2xl:max-w-screen-xl ${className}`}
    >
      {children}
    </div>
  );
};

export default Layout;
