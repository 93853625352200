import React, { useState, useEffect } from "react";

const ThemeToggle = () => {
  const [darkMode, setDarkMode] = useState(() => {
    // Cek localStorage untuk mode tema saat ini
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "dark";
  });

  useEffect(() => {
    // Update tema di localStorage dan body class
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <button
      onClick={() => setDarkMode(!darkMode)}
      className={`relative flex items-center h-10 w-20 rounded-full transition-colors duration-300 ${
        darkMode ? "bg-slate-700" : "bg-gradient-to-r from from-yellow-500 to-yellow-400"
      }`}
    >
      <span
        className={`transform transition-transform duration-300 flex items-center justify-center w-8 h-8 rounded-full bg-white ${
          darkMode ? "translate-x-11" : "translate-x-1"
        }`}
      >
        {darkMode ? <span className="">🌙</span> : <span className="">☀️</span>}
      </span>
    </button>
  );
};

export default ThemeToggle;
