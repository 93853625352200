import React from "react";
import Icon from "../../Assets/Icon/Icon";
import Button from "../../Components/Button/Button";
import Modal from "../../Components/Modal/Modal";

const Confirmation = ({
  formData,
  handleChange,
  errorSubmit,
  content,
  showTnc,
  setShowTnc,
  loadingContent,
}) => {
  return (
    <>
      {errorSubmit.length > 0 && (
        <span className="flex flex-col text-red-500 items-start text-start gap-1 font-custom bg-red-50 border border-red-500 rounded-md px-3 py-1">
          <span className=" font-semibold text-sm flex items-center gap-1">
            <Icon className={"h-4 w-4"} name={"info-circle"} /> Terjadi
            kesalahan!
          </span>
          <ul className="text-xs ">
            {errorSubmit.map((el, index) => (
              <li key={index}>
                - <strong>{el.path[0]}</strong>: {el.message}
              </li>
            ))}
          </ul>
        </span>
      )}
      <div className="font-custom flex flex-col divide-y dark:text-white text-black">
        <div className="py-3">
          <strong>Nama Lengkap:</strong> {formData.name}
        </div>
        <div className="py-3">
          <strong>Nomor Whatsapp:</strong> {formData.phoneNumber}
        </div>
        <div className="py-3">
          <strong>Email:</strong> {formData.email}
        </div>
        <div className="py-3">
          <strong>Nama Subdomain:</strong> {formData.subdomainName}
        </div>
        <div className="py-3">
          <strong>Pilihan Website:</strong>{" "}
          {formData.selectedWebsite.websiteName}
        </div>
        <div className="py-3">
          <strong>Pilihan Paket:</strong> {formData.selectedPacket.packetName}
        </div>
        <div className="py-3">
          <strong>Durasi:</strong> {formData.selectedPacket.packetPeriod} bulan
        </div>
      </div>
      <div className="py-3">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            name="termsAccepted"
            className="form-checkbox"
            checked={formData.termsAccepted}
            onChange={handleChange}
          />
          <div className="flex flex-wrap items-center gap-1">
            <span className="ml-2 text-black dark:text-white">
              Saya setuju dengan{" "}
            </span>
            <span
              className={"text-blue-500 font-semibold"}
              onClick={() => setShowTnc(!showTnc)}
            >
              Syarat & Ketentuan
            </span>
          </div>
        </label>
      </div>
      {showTnc && (
        <Modal>
          <div className=" dark:bg-slate-700 bg-white">
            {loadingContent ? (
              <p>Loading...</p>
            ) : (
              <div className="flex flex-col px-4 py-2 gap-2 overflow-y-auto w-full h-screen font-custom">
                <p className="text-xl font-semibold dark:text-white ">
                  Syarat & Ketentuan
                </p>
                {content.data.attributes.terms_and_condition_registration.map(
                  (el, index) => (
                    <div key={index} className="dark:text-white text-black">
                      {el.children.map((el, indexs) => (
                        <p key={indexs}>{el.text}</p>
                      ))}
                    </div>
                  )
                )}
                <Button
                  type={"fill"}
                  size={"medium"}
                  onClick={() => setShowTnc(false)}
                >
                  Tutup
                </Button>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Confirmation;
