import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const [content, setContent] = useState({
    data: {
      attributes: {
        description: {
          description_header: "",
          description_detail: [{}],
        },
        how_to_integrate: {
          description_header: "",
          description_detail: [{}],
        },
        qna: [
          {
            label: "",
            open: "",
            value: "",
          },
        ],
        maintenance: "",
        headline: "",
        subheadline: "",
      },
    },
    meta: { pagination: { page: 1 } },
  });
  const [loadingContent, setLoadingContent] = useState(false);

  const handleContent = async () => {
    setLoadingContent(true);
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/wow-template-content`)
        .then((val) => {
          setContent(val.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingContent(false);
    }
  };

  useEffect(() => {
    handleContent();
  }, []);

  return (
    <ContentContext.Provider value={{ content, setContent, loadingContent }}>
      {children}
    </ContentContext.Provider>
  );
};

export default ContentProvider;
