import React from "react";
import Icon from "./Icon";

const Logo = () => {
  return (
    <>
      {/* <div className="md:flex hidden items-end">
        <Icon name={"wow-logo"} className={"w-52"} />
      </div> */}
      <div className="flex items-center justify-center xl:bg-gradient-to-br xl:from-slate-100 xl:to-blue-200 xl:p-2 xl:rounded-full">
        <Icon
          name={"wow-logo-mobile"}
          className={"xl:w-12 xl:h-12 w-16 h-16"}
        />
      </div>
    </>
  );
};

export default Logo;
