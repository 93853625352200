import React, { useContext } from "react";
import Label from "../../Components/Label/Label";
import Layout from "../../Components/Layout/Layout";
import Navbar from "../../Components/Navbar/Navbar";
import faq from "../../Assets/Image/faq.svg";
import { ContentContext } from "../../Context/ContentProvider";
import Icon from "../../Assets/Icon/Icon";
import Footer from "../../Components/Footer/Footer";

const Faq = () => {
  const { content, setContent, loadingContent } = useContext(ContentContext);
  return (
    <>
      <Navbar />
      <div className="relative flex justify-center dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 bg-slate-50">
        <Layout className={"py-4"}>
          <div className="xl:grid xl:grid-cols-2 flex flex-col font-custom ">
            <div className="w-full flex flex-col divide-y gap-4 z-20">
              <Label
                type={"section-title"}
                className={"w-full dark:!text-white text-black"}
              >
                Pertanyaan yang sering tanyakan
              </Label>
              {loadingContent ? <p>Loading...</p> : <></>}
              <>
                {content.data.attributes.qna.map((el, index) => (
                  <div key={index}>
                    <div
                      className={"flex items-center justify-between "}
                      onClick={() => {
                        const updatedQna = content.data.attributes.qna.map(
                          (qnaItem, i) => ({
                            ...qnaItem,
                            open: i === index ? !qnaItem.open : false, // Toggle the clicked item, close others
                          })
                        );
                        setContent({
                          ...content,
                          data: {
                            ...content.data,
                            attributes: {
                              ...content.data.attributes,
                              qna: updatedQna,
                            },
                          },
                        });
                      }}
                    >
                      <p className="text-black text-2xl font-medium py-2 dark:text-white">
                        {el.label}
                      </p>
                      {el.open ? (
                        <Icon
                          name={"dash"}
                          className={"h-7 w-7 text-black dark:text-white p-0"}
                        />
                      ) : (
                        <Icon
                          name={"plus"}
                          className={"h-7 w-7 text-black dark:text-white"}
                        />
                      )}
                    </div>
                    {el.open && (
                      <p className="text-gray-500 dark:text-gray-300">
                        {el.value}
                      </p>
                    )}
                  </div>
                ))}
              </>
            </div>
            <div className="w-full flex justify-center z-30">
              <img src={faq} alt="faq" className="max-w-md" />
            </div>
          </div>
        </Layout>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="absolute bottom-0 w-full z-0 dark:hidden"
        >
          <path
            fill="#f8fafc"
            d="M0 192l48 5.3c48 5.7 144 15.7 240-10.6C384 160 480 96 576 74.7c96-21.7 192 .3 288 32 96 32.3 192 74.3 288 64C1248 160 1344 96 1392 64l48-32v288H0z"
          ></path>
        </svg>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
