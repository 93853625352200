import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";

const Menu = ({ item }) => {
  const navigate = useNavigate();

  return (
    <>
      <li className="hover:bg-gray-100 dark:bg-slate-900 hover:rounded-md hover:w-full relative xl:p-2 py-2 xl:block hidden">
        <Button
          onClick={() =>
            navigate({
              pathname: "/template-websites",
              search: `?category=${item.attributes.slug}`,
            })
          }
          type={"link"}
          size={"medium"}
          className={`xl:!flex !hidden !py-1 !justify-between !w-full !items-center !gap-2 !font-normal hover:!no-underline !text-black dark:!text-white `}
        >
          {item.attributes.desc}{" "}
        </Button>
      </li>

      {/* Mobile */}
      <li className="block xl:hidden">
        <Button
          onClick={() =>
            navigate({
              pathname: "/template-websites",
              search: `?category=${item.attributes.slug}`,
            })
          }
          type={"link"}
          className={`!flex !text-xl !px-0 !pl-4 !justify-between !w-full !items-center !gap-2 !font-normal hover:!no-underline !text-black dark:!text-white`}
        >
          {item.attributes.desc}{" "}
        </Button>
      </li>
    </>
  );
};

export default Menu;
