import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorHandler from "../../Components/ErrorHandler/ErrorHandler";
import { getErrorMessage } from "../../Utils/errorUtils";

const OrderStatus = () => {
  const order_id = useParams();
  const [orderData, setOrderData] = useState({
    attributes: {
      status: "",
      full_name: "",
    },
    notFound: false,
  });
  const [loadingOrderData, setLoadingOrderData] = useState();
  const [error, setError] = useState(null);

  const handleDetailOrder = useCallback(async () => {
    setLoadingOrderData(true);
    setError(null);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/template-website-orders?filters[order_id][$eq]=${order_id.order_id}&populate=*`
        )
        .then((val) => {
          if (val.data.data.length > 0) {
            setOrderData(val.data.data[0]);
          } else {
            setOrderData((val) => ({ ...val, notFound: true }));
          }
        });
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setLoadingOrderData(false);
    }
  }, [order_id.order_id]);

  useEffect(() => {
    handleDetailOrder();
  }, [handleDetailOrder]);

  return (
    <div className="flex flex-row bg-slate-50 h-screen">
      <div className="xl:w-2/5 w-full flex flex-col gap-3 mx-auto">
        <div className="flex items-center gap-2">
          {error !== null ? (
            <ErrorHandler error={error} onRetry={handleDetailOrder} /> //error handle
          ) : (
            <>
              {loadingOrderData ? (
                <p>Loading...</p>
              ) : (
                <>
                  {orderData.notFound !== true ? (
                    <div className="flex flex-col gap-2">
                      <p className="text-xl font-custom font-semibold">
                        Order # {orderData.attributes.order_id}
                      </p>
                      <ul className="font-custom flex flex-col gap-2">
                        <li>
                          {" "}
                          <strong>Nama:</strong>{" "}
                          {orderData.attributes.full_name}
                        </li>
                        <li>
                          <strong>Status:</strong> {orderData.attributes.status}
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-2">
                      <p className="text-xl font-custom font-semibold">
                        Order # {orderData.attributes.order_id}
                      </p>
                      <ul className="font-custom flex flex-col divide-y">
                        <li>
                          {" "}
                          <strong>Pesanan Tidak Ditemukan</strong>{" "}
                          {orderData.attributes.full_name}
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
