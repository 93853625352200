import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import Card from "../../Components/Card/Card";
import ErrorHandler from "../../Components/ErrorHandler/ErrorHandler";
import Footer from "../../Components/Footer/Footer";
import Label from "../../Components/Label/Label";
import Layout from "../../Components/Layout/Layout";
import Navbar from "../../Components/Navbar/Navbar";
import { useFavorites } from "../../Context/FavoritesProvider";
import { getErrorMessage } from "../../Utils/errorUtils";

const Favorite = () => {
  const [favorite, setFavorite] = useState([]);
  const [loadingFavirite, setLoadingFavorite] = useState(false);
  const [error, setError] = useState(null);
  const { favorites } = useFavorites();

  const handleTemplate = useCallback(async () => {
    setLoadingFavorite(true);
    setError(null);
    try {
      favorites.length > 0 &&
        (await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/template-websites/?${favorites
              .map((el, index) => `filters[id][$in][${index}]=${el}&`)
              .join("")}populate=*`
          )
          .then((val) => {
            setFavorite(val.data.data);
          }));
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setLoadingFavorite(false);
    }
  }, [favorites]);

  useEffect(() => {
    handleTemplate();
  }, [handleTemplate]);

  return (
    <div className="bg-slate-50 dark:bg-gradient-to-b dark:from-slate-900 dark:to-slate-800 transition-colors duration-300">
      <Navbar />
      <Layout className={"mb-6"}>
        <Label className={"text-black dark:text-white"} type={"section-title"}>
          Template Website Favoritmu
        </Label>
        {error !== null ? (
          <ErrorHandler error={error} onRetry={handleTemplate} /> //error handle
        ) : (
          <>
            {loadingFavirite ? (
              <div className="grid grid-cols-3 gap-4 animate-pulse">
                {Array.from(Array(6), (e, i) => (
                  <div
                    key={i}
                    className="w-full h-[320px] rounded-lg bg-slate-200 dark:bg-slate-600 animate-pulse"
                  ></div>
                ))}
              </div>
            ) : (
              <>
                {favorites.length > 0 ? (
                  <div className="grid xl:grid-cols-3 grid-cols-1 gap-4 mt-4">
                    {favorite?.map((val, index) => (
                      <Card
                        route={`/template-websites/${val.attributes.slug}`}
                        id={val.id}
                        key={index}
                        category={
                          val.attributes.template_website_category.data
                            .attributes.desc
                        }
                        images={val.attributes.website_image.data}
                        title={val.attributes.website_name}
                        description={val.attributes.website_description}
                        count_view={val.attributes.count_website_view}
                        count_like={val.attributes.count_website_like}
                        price={val.attributes.website_start_price}
                      />
                    ))}
                  </div>
                ) : (
                  <Label type={"section-desc"}>
                    Belum ada template favorit
                  </Label>
                )}
              </>
            )}
          </>
        )}
      </Layout>
      <Footer />
    </div>
  );
};

export default Favorite;
