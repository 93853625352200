import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Icon from "../../Assets/Icon/Icon";
import Badges from "../../Components/Badges/Badges";
import Button from "../../Components/Button/Button";
import Card from "../../Components/Card/Card";
import ErrorHandler from "../../Components/ErrorHandler/ErrorHandler";
import Footer from "../../Components/Footer/Footer";
import Label from "../../Components/Label/Label";
import Layout from "../../Components/Layout/Layout";
import Navbar from "../../Components/Navbar/Navbar";
import { getErrorMessage } from "../../Utils/errorUtils";

const AllTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterWebsite, setFilterWebsite] = useState({ data: [] });
  const [pagination, setPagination] = useState(
    `&pagination[page]=1&pagination[pageSize]=9&`
  );
  const [website, setWebsite] = useState({
    data: [],
    meta: { pagination: { page: 1 } },
  });
  const [isOpenDropdown, setIsOpenDropdown] = useState({
    filterCategory: false,
    sortCategory: false,
  });
  const [loadingWebsite, setLoadingWebsite] = useState(false);
  const [loadingFilterWebsite, setLoadingFilterWebsite] = useState(false);
  const [websiteError, setWebsiteError] = useState(null);
  const [filterWebsiteError, setFilterWebsiteError] = useState(null);
  const [selectedSort, setSelectedSort] = useState({
    value: "sort[0]=website_name:asc",
    label: "A - Z",
  });
  const [activeCategory, setActiveCategory] = useState("Semua");
  const handleCategory = (value, desc) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (value === "") {
      newSearchParams.delete("category");
      newSearchParams.delete("searchKey");
    } else {
      newSearchParams.delete("searchKey");
      newSearchParams.set("category", value);
    }

    setSearchParams(newSearchParams);
    setActiveCategory(desc);
  };

  const handleSortOptionClick = (value, label) => {
    setSelectedSort((prev) => ({ ...prev, value: value, label: label }));
    setIsOpenDropdown((prev) => ({ ...prev, sortCategory: false }));
  };

  const handleWebsite = useCallback(async () => {
    setLoadingWebsite(true);
    setWebsiteError(null);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/template-websites${
            searchParams.get("category")
              ? `?filters[template_website_category][slug][$eq]=${searchParams.get(
                  "category"
                )}&`
              : searchParams.get("searchKey")
              ? `?filters[website_name][$contains]=${searchParams.get(
                  "searchKey"
                )}&`
              : "?"
          }${pagination}${selectedSort.value}&populate=*`
        )
        .then((val) => {
          setWebsite(val.data);
        });
    } catch (error) {
      setWebsiteError(getErrorMessage(error));
    } finally {
      setLoadingWebsite(false);
    }
  }, [searchParams, selectedSort.value, pagination]);

  const handleFilterWebsite = useCallback(async () => {
    setLoadingFilterWebsite(true);
    setFilterWebsiteError(null);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/template-website-categories?populate=*`
        )
        .then((val) => {
          setFilterWebsite(val.data);
          const categoryLabel = val.data.data.find(
            (label) => label.attributes.slug === searchParams.get("category")
          );
          setActiveCategory(
            categoryLabel !== undefined
              ? categoryLabel.attributes.desc
              : "Semua"
          );
        });
    } catch (error) {
      setFilterWebsiteError(getErrorMessage(error));
    } finally {
      setLoadingFilterWebsite(false);
    }
  }, [searchParams]);

  useEffect(() => {
    handleWebsite();
  }, [handleWebsite]);

  useEffect(() => {
    handleFilterWebsite();
  }, [handleFilterWebsite]);

  return (
    <>
      <div className="dark:bg-gradient-to-b dark:from-slate-900 dark:to-slate-800 transition-colors duration-300 bg-slate-50">
        <Navbar />
        <Layout className={"mb-6"}>
          {searchParams.get("searchKey") !== null && (
            <Label
              type={"section-title"}
              className={"!text-nowrap !text-xl xl:!text-3xl"}
            >{`Cari Website: "${searchParams.get("searchKey")}"`}</Label>
          )}
          <div className="flex w-full justify-between items-center">
            {/* Categories */}
            {filterWebsiteError !== null ? (
              <ErrorHandler
                error={websiteError}
                onRetry={handleFilterWebsite}
              /> //error handle
            ) : (
              <>
                {loadingFilterWebsite ? (
                  <div className="flex items-center gap-2">
                    {Array.from(Array(5), (e, i) => (
                      <Badges
                        key={i}
                        size={"medium"}
                        className={
                          "!bg-slate-200 dark:!bg-slate-600 animate-pulse w-20 h-8"
                        }
                      ></Badges>
                    ))}
                  </div>
                ) : (
                  <div className="flex items-center gap-2 overflow-x-auto">
                    <Badges
                      size={"medium"}
                      className={
                        "hover:bg-blue-100 dark:hover:bg-slate-700 !shrink-0"
                      }
                      active={activeCategory === "Semua"}
                      handleClick={() => handleCategory("", "Semua")}
                    >
                      Semua
                    </Badges>
                    {filterWebsite.data.map((el, index) => (
                      <Badges
                        key={index}
                        size={"medium"}
                        className={
                          "hover:bg-blue-100 dark:hover:bg-slate-700 !shrink-0"
                        }
                        active={activeCategory === el.attributes.desc}
                        handleClick={() =>
                          handleCategory(el.attributes.slug, el.attributes.desc)
                        }
                      >
                        {el.attributes.desc}
                      </Badges>
                    ))}
                  </div>
                )}
              </>
            )}

            {/* Sort */}
            <div className="relative ">
              <div>
                <button
                  onClick={() =>
                    setIsOpenDropdown((prevState) => ({
                      ...prevState,
                      sortCategory: !prevState.sortCategory,
                    }))
                  }
                  type="button"
                  className="xl:flex xl:font-custom items-center xl:justify-between xl:w-full xl:rounded-full xl:border xl:border-gray-300 xl:shadow-sm xl:px-4 xl:py-2 xl:bg-white xl:text-sm xl:font-medium text-gray-700 hover:xl:bg-gray-50 focus:xl:outline-none dark:bg-slate-900 hover:dark:bg-slate-900 dark:text-white"
                >
                  <p className="xl:block hidden">
                    Urutkan: {selectedSort.label}
                  </p>
                  <Icon
                    name={"chevronDown"}
                    className={"h-4 w-4 ml-3 text-black hidden xl:block"}
                  />
                  <Icon
                    name={"filter-circle"}
                    className={"h-6 w-6 text-gray-500 block xl:hidden"}
                  />
                </button>
              </div>
              {isOpenDropdown.sortCategory && (
                <div className="origin-top-right z-20 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-900 hover:dark:bg-slate-900">
                  <div className="py-1 font-custom">
                    <button
                      onClick={() =>
                        handleSortOptionClick(
                          "sort[0]=website_name:asc",
                          "A - Z"
                        )
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:dark:bg-slate-900 dark:text-white w-full text-left"
                    >
                      A - Z
                    </button>
                    <button
                      onClick={() =>
                        handleSortOptionClick(
                          "sort[0]=website_name:desc",
                          "Z - A"
                        )
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:dark:bg-slate-900 dark:text-white w-full text-left"
                    >
                      Z - A
                    </button>
                    <button
                      onClick={() =>
                        handleSortOptionClick(
                          "sort[0]=publishedAt:desc",
                          "Terakhir Dibuat"
                        )
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:dark:bg-slate-900 dark:text-white w-full text-left"
                    >
                      Terakhir Dibuat
                    </button>
                    <button
                      onClick={() =>
                        handleSortOptionClick(
                          "sort[0]=updatedAt:desc",
                          "Terakhir Diubah"
                        )
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:dark:bg-slate-900 dark:text-white w-full text-left"
                    >
                      Terakhir Diubah
                    </button>
                    <button
                      onClick={() =>
                        handleSortOptionClick(
                          "sort[0]=website_name:desc",
                          "Terbanyak Dilihat"
                        )
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:dark:bg-slate-900 dark:text-white w-full text-left"
                    >
                      Terbanyak Terjual
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row space-x-4 mt-2 relative">
            {websiteError !== null ? (
              <ErrorHandler error={websiteError} onRetry={handleWebsite} /> //error handle
            ) : (
              <>
                {loadingWebsite ? (
                  <div className="grid xl:grid-cols-3 grid-cols-1 gap-4 animate-pulse">
                    {Array.from(Array(6), (e, i) => (
                      <div
                        key={i}
                        className="w-full h-[320px] rounded-lg bg-slate-200 animate-pulse dark:bg-slate-600"
                      ></div>
                    ))}
                  </div>
                ) : (
                  <>
                    <div className="grid xl:grid-cols-3 grid-cols-1 gap-4">
                      {website.data.length > 0 ? (
                        website?.data.map((val, index) => (
                          <Card
                            route={`/template-websites/${val.attributes.slug}`}
                            id={val.id}
                            key={index}
                            category={
                              val.attributes.template_website_category.data
                                .attributes.desc
                            }
                            images={val.attributes.website_image.data}
                            title={val.attributes.website_name}
                            description={val.attributes.website_description}
                            price={val.attributes.website_start_price}
                            slug={val.attributes.slug}
                          />
                        ))
                      ) : (
                        <Label type={"section-desc"} className={"!text-nowrap"}>
                          Template Tidak Ditemukan
                        </Label>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {website.data.length > 0 && (
            <div className="flex w-full justify-end gap-2">
              <Button
                type={"link"}
                size={"medium"}
                className={
                  website.meta.pagination.page === 1 &&
                  "!text-blue-300 hover:!no-underline"
                }
                onClick={() =>
                  website.meta.pagination.page === 1
                    ? ""
                    : setPagination(
                        `&pagination[page]=${
                          website.meta.pagination.page - 1
                        }&pagination[pageSize]=8&`
                      )
                }
              >
                Prev
              </Button>
              <Button
                type={"link"}
                size={"medium"}
                className={
                  website.meta.pagination.page ===
                    website.meta.pagination.pageCount &&
                  "!text-blue-300 hover:!no-underline"
                }
                onClick={() =>
                  website.meta.pagination.page ===
                  website.meta.pagination.pageCount
                    ? ""
                    : setPagination(
                        `&pagination[page]=${
                          website.meta.pagination.page + 1
                        }&pagination[pageSize]=8&`
                      )
                }
              >
                Next
              </Button>
            </div>
          )}
        </Layout>
        <Footer />
      </div>
    </>
  );
};

export default AllTemplate;
