import React, { useContext } from "react";
import { ContentContext } from "../../Context/ContentProvider";
import Button from "../Button/Button";

const ContactUs = () => {
  const { content } = useContext(ContentContext);
  const handleNeedHelp = () => {
    const message = `Halo, saya ingin bertanya / konsultasi tentang Website`;
    const whatsappUrl = `https://wa.me/${
      content.data.attributes.admin_phone_number
    }?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };
  return (
    <div className="px-3 xl:container xl:mx-auto xl:py-10 py-7 flex flex-col xl:flex-row justify-center items-center w-full gap-4">
      <div className="">
        <p className="text-2xl font-custom font-semibold text-center xl:text-start">
          Punya pertanyaan atau kebutuhan website khusus?
        </p>
      </div>
      <Button
        type={"outline"}
        size={"medium"}
        className={"!bg-white !text-xl !font-semibold"}
        onClick={() => handleNeedHelp()}
      >
        Hubungi Kami
      </Button>
    </div>
  );
};

export default ContactUs;
