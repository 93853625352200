// ScrollToTopButton.js
import React, { useContext } from "react";
import Icon from "../../Assets/Icon/Icon";
import { ContentContext } from "../../Context/ContentProvider";
import { ScrollContext } from "../../Context/ScrollProvider";
import Tooltip from "../Tooltip/Tooltip";
import Button from "./Button";

const ScrollToTopButton = () => {
  const isScrolled = useContext(ScrollContext);
  const { content } = useContext(ContentContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNeedHelp = () => {
    const message = `Halo, saya ingin bertanya / konsultasi tentang Website`;
    const whatsappUrl = `https://wa.me/${
      content.data.attributes.admin_phone_number
    }?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };

  return (
    <>
      <div className="fixed xl:bottom-5 bottom-2 z-20 xl:right-7 right-2 flex flex-col gap-2 items-center">
        {isScrolled && (
          <Button
            type={"icon"}
            size={"medium"}
            className={
              "bg-gradient-to-r from-cyan-500 via-sky-500 to-blue-500 !p-2"
            }
            onClick={scrollToTop}
          >
            <Icon name={"chevronUp"} className={"h-6 w-6 text-white"} />
          </Button>
        )}
        <Tooltip message={`Butuh bantuan?`} className={"mt-24"}>
          <Button
            type={"icon"}
            className={"!p-0"}
            onClick={() => handleNeedHelp()}
          >
            <Icon name={"whatsapp"} className={"h-14 w-14"} />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default ScrollToTopButton;
