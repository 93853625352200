import { useNavigate } from "react-router-dom";
import Icon from "../../Assets/Icon/Icon";
import Button from "../Button/Button";
import ThemeToggle from "../Button/ThemeToggle";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import SearchBar from "../Input/SearchBar";
import Menu from "./Menu";

const SideMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  showExplore,
  setShowExplore,
  loadingFilter,
  error,
  handleNavbar,
  filterTemplate,
  setShowSearchBar,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`fixed overflow-y-scroll font-custom top-0 left-0 w-full h-full z-30 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 shadow-lg transform transition-transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4 flex flex-col gap-4">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-1">
              <Button
                type={`icon`}
                className={`!p-0 !relative !flex`}
                onClick={() => navigate("/favorite")}
              >
                <div
                  className={`rounded-full relative inline-flex  p-3  items-center justify-center backdrop-blur-sm bg-blue-200 hover:bg-blue-300 `}
                >
                  <Icon name={"heart"} className={"h-6 w-6 text-black"} />
                </div>
              </Button>
              <ThemeToggle />
            </div>
            <Button
              type={`icon`}
              className={`!p-0 !flex `}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Icon
                name={"x"}
                className={"h-10 w-10 text-black dark:text-white"}
              />
            </Button>
          </div>
          <SearchBar handleClose={() => setShowSearchBar(false)} />
          <ul className="w-full flex flex-col gap-2 px-2">
            <li>
              <div
                className={
                  "flex flex-col xl:flex-row items-center cursor-pointer "
                }
              >
                <div
                  className="flex items-center w-full justify-between"
                  onClick={() =>
                    setShowExplore({
                      ...showExplore,
                      template: !showExplore.template,
                    })
                  }
                >
                  <p className="text-black dark:text-white text-2xl font-medium py-2">
                    Website
                  </p>

                  {showExplore.template ? (
                    <Icon
                      name={"dash"}
                      className={"h-10 w-10 text-black dark:text-white p-0"}
                    />
                  ) : (
                    <Icon
                      name={"plus"}
                      className={"h-10 w-10 text-black dark:text-white"}
                    />
                  )}
                </div>
                {showExplore.template && (
                  <>
                    {error !== null ? (
                      <div className="flex flex-col absolute top-full w-max shadow-xl bg-white rounded-lg">
                        <ul className="p-2 space-y-1 divide-y">
                          <ErrorHandler error={error} onRetry={handleNavbar} />
                        </ul>
                      </div> //error handle
                    ) : (
                      <>
                        {loadingFilter ? (
                          <ul>
                            <li className="h-6 w-full bg-slate-200 dark:bg-slate-600"></li>
                            <li className="h-6 w-full bg-slate-200 dark:bg-slate-600"></li>
                          </ul>
                        ) : (
                          <div className="flex gap-2 w-full pl-4">
                            <div className="after:block after:bg-gray-400 after:w-[1px] after:h-full"></div>
                            <div className="flex flex-col w-full">
                              <ul>
                                {filterTemplate.data.map((el, index) => (
                                  <Menu
                                    key={index}
                                    item={el}
                                    showExplore={showExplore}
                                    setShowExplore={setShowExplore}
                                  />
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </li>
            <li>
              <div
                className={
                  "flex flex-col xl:flex-row items-center cursor-pointer "
                }
              >
                <div className="flex items-center w-full justify-between">
                  <p
                    onClick={() => navigate("/FAQ")}
                    className="text-black dark:text-white text-2xl font-medium py-2"
                  >
                    FAQ
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
