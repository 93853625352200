import React, { useContext } from "react";
import Layout from "../Layout/Layout";
import description from "../../Assets/Image/description.svg";
import Icon from "../../Assets/Icon/Icon";
import { ContentContext } from "../../Context/ContentProvider";
import Label from "../Label/Label";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

const DescriptionHomePage = ({ myRef }) => {
  const { content, loadingContent } = useContext(ContentContext);
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="py-4 font-custom" ref={myRef}>
        <div className="xl:grid xl:grid-cols-2 py-8 flex flex-col">
          <div className="w-full flex justify-center">
            <img
              src={description}
              alt="headline"
              className="object-cover xl:max-w-md"
            />
          </div>
          {loadingContent ? (
            <div className="flex flex-col gap-2">
              <div className="w-full h-2 bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
              <div className="w-full h-5 bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
              <div className="w-full h-5 bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
              <div className="w-full h-3 bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
              <div className="w-full h-3 bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
              <div className="w-full h-3 bg-slate-200 dark:bg-slate-600 animate-pulse rounded-lg"></div>
            </div>
          ) : (
            <div className="flex flex-col space-y-2 xl:space-y-4 justify-center">
              <p className="font-bold text-blue-500">
                Mengapa Website dari WOW Template?
              </p>
              <div className="space-y-1">
                <Label
                  type={"section-title"}
                  className={"text-black dark:text-white"}
                >
                  {content.data.attributes.description.description_header}
                </Label>
                <Label
                  type={"section-desc"}
                  className={"text-gray-500 xl:block hidden dark:text-gray-300"}
                >
                  {content.data.attributes.description.description_sub}
                </Label>
              </div>
              {content.data.attributes.description.description_detail.map(
                (el, index) => (
                  <div
                    className="flex items-center text-start gap-2"
                    key={index}
                  >
                    <span className="flex items-center justify-center">
                      <Icon
                        name={el.icon}
                        className={"h-6 w-6 text-green-500"}
                      />
                    </span>
                    <div className="flex flex-col">
                      <p className="text-xl font-custom text-gray-700 dark:text-white">
                        {el.heading}
                      </p>
                    </div>
                  </div>
                )
              )}
              <Button
                type={"fill"}
                size={"medium"}
                onClick={() => navigate("/template-websites")}
                className={
                  "flex items-center w-fit gap-2 hover:bg-gradient-to-r hover:from-cyan-600 hover:via-sky-600 hover:to-blue-600 hover:shadow-xl"
                }
              >
                Cari Website Impian Anda{" "}
                <Icon className={"h-5 w-5"} name={"arrowRight"} />
              </Button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DescriptionHomePage;
